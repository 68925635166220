import { Api } from '../axios-config';

export interface IRegisterUserDto {
  email: string;
  password: string;
  fullName: string;
  indication?: string;
}

export interface IUserInterface {
  id: number;
  email: string;
  cidadeId: number;
  nomeCompleto: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IUserProcessing {
  userToken: string;
  quantity: string; // BigInteger representado como string
  energy: string; 
  total: string;
  email: string;
  fullName: string;
  createdAt: string;
  winKey: boolean;

}
export interface IUserRankProcess {
  userToken: string;
  quantity: string;
  fullname: string;
}

export interface IUserProcessingHistory {
  updateDate: string;
  totalQuantity: number;
}


export interface MinersProcessed {
  quantity: string; // BigInteger representado como string
}

export interface IUminersOnline {
  id: string;
  fullName: string;
}

export interface IUIndication {
  id: string;
  idUID: string;
  fullName: string;
  totalProcess: string;
}

export interface IUIndicationList {
  id: string;
  indications: IUIndication[];
}

const getMe = async (): Promise<IUserInterface | Error> => {
  try {
    const { data } = await Api.get<IUserInterface>('/users/me');

    if (data) {
      return data;
    }

    return new Error('Erro ao obter os dados do usuário.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao obter os dados do usuário.');
  }
};

const getUserProcessing = async (): Promise<IUserProcessing | Error> => {
  try {
    const { data } = await Api.get<IUserProcessing>('/users/process');

    if (data) {
      return data;
    }

    return new Error('Erro ao obter o processamento do usuário.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao obter o processamento do usuário.');
  }
};

const getUserProcessingHistory = async (): Promise<IUserProcessingHistory[] | Error> => {
  try {
    const { data } = await Api.get<IUserProcessingHistory[]>('/users/process-history');

    if (data) {
      return data;
    }

    return new Error('Erro ao obter o processamento do usuário.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao obter o processamento do usuário.');
  }
};

const getUserProcessingHistoryById = async (idUID: string): Promise<IUserProcessingHistory[] | Error> => {
  try {
    const { data } = await Api.get<IUserProcessingHistory[]>('/users/process-history/' + idUID);

    if (data) {
      return data;
    }

    return new Error('Erro ao obter o processamento do usuário indicado.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao obter o processamento do usuário indicado.');
  }
};

const getProcessRank = async (): Promise<IUserRankProcess[] | Error> => {
  try {
    // Aqui você pode usar a userList conforme necessário, por exemplo, enviando-a em uma requisição
    const { data } = await Api.get<IUserRankProcess[]>('/users/processRank');

    if (data) {
      return data;
    }

    return new Error('Erro ao obter o rank de processamento.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao obter o rank de processamento.');
  }
};

const getProcessRank7Day = async (): Promise<IUserRankProcess[] | Error> => {
  try {
    // Aqui você pode usar a userList conforme necessário, por exemplo, enviando-a em uma requisição
    const { data } = await Api.get<IUserRankProcess[]>('/users/processRank7Days');

    if (data) {
      return data;
    }

    return new Error('Erro ao obter o rank de processamento.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao obter o rank de processamento.');
  }
};


const getPastUserProcessing = async (): Promise<MinersProcessed[] | Error> => {
  try {
    const { data } = await Api.get<MinersProcessed[]>('/users/process-miners');

    // Verifica se retornou um array de dados
    if (Array.isArray(data)) {
      return data; // Retorna o array de dados de processamento histórico
    }

    return new Error('Erro ao obter os dados de processamento passados.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao obter os dados de processamento passados.');
  }
};

const getOnlineUsers = async (): Promise<number | Error> => {
  try {
    const { data } = await Api.get<number>('/users/miners');

    if (typeof data === 'number' && !isNaN(data)) {
      return data; // Retorna o número de mineradores online
    }

    return new Error('Erro ao obter o processamento do usuário. Valor retornado não é um número válido.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao obter o processamento do usuário.');
  }
};

const getIndication = async (): Promise<IUIndicationList | Error> => {
  try {
    const { data } = await Api.get<IUIndicationList>('/users/indication');

    if (data) {
      return data;
    }

    return new Error('Erro ao obter as indicações do usuário.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao obter as indicações do usuário.');
  }
};

const register = async (registerUserDto: IRegisterUserDto): Promise<IUserInterface | Error> => {
  try {
    const { data } = await Api.post<IUserInterface>('/auth/signup', registerUserDto);

    if (data) {
      return data;
    }

    return new Error('Erro ao cadastrar o usuário.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao cadastrar o usuário.');
  }
};

export const UserService = {
  getMe,
  getUserProcessing,
  getPastUserProcessing,  // Função adicionada
  register,
  getProcessRank7Day,
  getUserProcessingHistory,
  getUserProcessingHistoryById,
  getIndication,
  getOnlineUsers,
  getProcessRank
};
