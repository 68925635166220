import { Box, Card, CardContent, Typography } from '@mui/material';
import { LayoutBaseDePagina } from '../../shared/layouts';

export const DivisaoDeLucros = () => {
    return (
        <LayoutBaseDePagina
            titulo="Divisão do Prêmio"
            barraDeFerramentas={null}
        >
            <Box width="100%" display="flex" flexDirection="column" alignItems="center" padding={0}>
                <Card style={{ margin: "10px" }}>
                    <CardContent>
                        {/* Introdução */}
                        <Box width="100%" maxWidth="800px" marginBottom={4}>
                            <Typography variant="h4" align="center" gutterBottom color="secondary">
                                Como os Prêmios São Divididos
                            </Typography>
                            <Typography variant="body1" paragraph>
                                No nosso projeto, todos os participantes têm sua contribuição valorizada. A divisão de Prêmios foi cuidadosamente planejada 
                                para recompensar o trabalho em equipe, incentivar os mineradores e fortalecer o ecossistema do token <strong>Energy</strong>.
                            </Typography>
                        </Box>

                        {/* Divisão de Prêmios */}
                        <Box width="100%" maxWidth="800px" marginBottom={4}>
                            <Typography variant="h5" align="center" gutterBottom color="secondary">
                                A Distribuição
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Os Prêmios gerados são distribuídos de forma justa e transparente:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography variant="body1" paragraph color="text.primary">
                                        <strong>30% para a equipe de desenvolvimento:</strong> para financiar melhorias, manutenção e expansão do projeto.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1" paragraph color="text.primary">
                                        <strong>20% para o minerador que encontrar a chave:</strong> uma recompensa direta pelo trabalho bem-sucedido.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body1" paragraph color="text.primary">
                                        <strong>50% para liquidez do token Energy:</strong> esse valor é investido diretamente na pool de liquidez do token, fortalecendo sua estabilidade e incentivando a participação na rede.
                                    </Typography>
                                </li>
                            </ul>
                        </Box>

                        {/* Token Energy */}
                        <Box width="100%" maxWidth="800px" marginBottom={4}>
                            <Typography variant="h5" align="center" gutterBottom color="secondary">
                                O que é o Token Energy?
                            </Typography>
                            <Typography variant="body1" paragraph>
                                O <strong>Energy</strong> é o token oficial do projeto. Cada vez que você ajuda a processar dados, você ganha uma parte desse token. 
                                Ele não só representa uma recompensa pelo seu esforço, mas também permite que você participe da economia do projeto, sendo utilizado 
                                para transações, trocas e muito mais.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Quanto mais você contribui, mais <strong>Energy</strong> você acumula, criando um ciclo de benefícios mútuos entre os participantes 
                                e o crescimento da plataforma.
                            </Typography>
                        </Box>

                        {/* Chamada de Ação */}
                        <Box width="100%" maxWidth="800px" marginBottom={4} textAlign="center">
                            <Typography variant="h5" gutterBottom color="secondary">
                                Faça Parte Desta Revolução!
                            </Typography>
                            <Typography variant="body1">
                                Contribua com nosso projeto e aproveite os Prêmios enquanto ajudamos a resolver um dos maiores desafios da blockchain!
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </LayoutBaseDePagina>
    );
};
