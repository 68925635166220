import { Box, Paper, useTheme } from '@mui/material';

interface ICotacaoProps {
    mostrarDolar?: boolean;
    mostrarBitcoin?: boolean;
    mostrarEnergia?: boolean;
    valorDolar?: string;
    valorBitcoin?: string;
    valorEnergia?: string;
}

export const Cotacao: React.FC<ICotacaoProps> = ({
    mostrarDolar = false,
    mostrarBitcoin = false,
    mostrarEnergia = false,
    valorDolar = '',
    valorBitcoin = '',
    valorEnergia = '',
}) => {
    const theme = useTheme();

    // Função para formatar os valores
    // Função para formatar os valores
    const formatarValor = (valor: string, moeda: 'BRL' | 'USD' = 'USD') => {
        const numero = parseFloat(valor);
        return isNaN(numero) 
            ? valor 
            : numero.toLocaleString('en-US', { style: 'currency', currency: moeda });
    };


    return (
        <Box
            gap={2}
            marginX={2}
            padding={3}
            display="flex"
            flexDirection="row"
            alignItems="center"
            component={Paper}
            elevation={4}
            style={{ backgroundColor: theme.palette.background.default, borderRadius: theme.spacing(1), border: `1px solid ${theme.palette.divider}` }}
        >
            {mostrarDolar && (
                <Box display="flex" alignItems="center" gap={1} style={{ color: theme.palette.success.main }}>
                    <span style={{ fontSize: theme.typography.h5.fontSize, fontWeight: 'bold' }}>&#36;</span> {/* Ícone do Dólar */}
                    <span style={{ fontSize: theme.typography.body1.fontSize }}>Dólar: {formatarValor(valorDolar, 'BRL')}</span>
                </Box>
            )}

            {mostrarBitcoin && (
                <Box display="flex" alignItems="center" gap={1} style={{ color: theme.palette.warning.main }}>
                    <span style={{ fontSize: theme.typography.h5.fontSize, fontWeight: 'bold' }}>&#8383;</span> {/* Ícone do Bitcoin */}
                    <span style={{ fontSize: theme.typography.body1.fontSize }}>Bitcoin: {formatarValor(valorBitcoin)}</span>
                </Box>
            )}

            {mostrarEnergia && (
                <Box display="flex" alignItems="center" gap={1} style={{ color: theme.palette.info.main }}>
                    <img
                        src="/energiaIcon.png"
                        alt="Ícone de Energia"
                        style={{ width: theme.spacing(3), height: theme.spacing(3) }}
                    />
                    <span style={{ fontSize: theme.typography.body1.fontSize }}>Energia: {formatarValor(valorEnergia)}</span>
                </Box>
            )}

        </Box>
    );
};
