import { useEffect, useState } from 'react';
import { Box, Card, CardContent, CircularProgress, List, ListItem, ListItemText, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import { IUserRankProcess, UserService } from '../../shared/services/api/users/UserService';
import { FerramentasDaListagem } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';

// Registre os elementos necessários para o Pie Chart e o Line Chart
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);

export const Rank = () => {
  const [isLoadingProcessing, setIsLoadingProcessing] = useState(true);
  const [usersProcessRank, setUsersProcessRank] = useState<IUserRankProcess[] | undefined>();
  const [usersProcessRank7Day, setUsersProcessRank7Day] = useState<IUserRankProcess[] | undefined>();





  // Busca o rank de processamento dos usuários
  useEffect(() => {
    setIsLoadingProcessing(true);//aki
    UserService.getProcessRank()
      .then((result) => {
        setIsLoadingProcessing(false);

        if (result instanceof Error) {
          alert(result.message);
        } else {
          setUsersProcessRank(result);
        }
      });
  }, []);

  // Busca o rank de processamento dos usuários
  useEffect(() => {
    setIsLoadingProcessing(true);//aki
    UserService.getProcessRank7Day()
      .then((result) => {
        setIsLoadingProcessing(false);

        if (result instanceof Error) {
          alert(result.message);
        } else {
          setUsersProcessRank7Day(result);
        }
      });
  }, []);


  return (
    <LayoutBaseDePagina
      titulo="Rank"
      barraDeFerramentas={<FerramentasDaListagem mostrarBotaoNovo={false} />}
    >
      <Box display="flex" flexWrap="wrap" justifyContent="center" gap={2} margin={2}>
        <Card sx={{ width: '49%', maxWidth: '100%' }}>
          <CardContent>
            <Typography variant="h5" align="center" gutterBottom>
              Rank de Processamento de Usuários
            </Typography>

            <Box padding={2} display="flex" flexDirection="column" alignItems="center">
              {isLoadingProcessing && <CircularProgress />}

              {!isLoadingProcessing && usersProcessRank !== undefined && usersProcessRank.length > 0 && (
                <List sx={{ width: '100%' }}>
                  {usersProcessRank.map((user, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        borderBottom: '1px solid #ddd',
                        '&:last-child': { borderBottom: 'none' },
                        padding: '10px 0',
                      }}
                    >
                      <ListItemText
                        primary={user.fullname} // Ajuste para a propriedade correta
                        secondary={`Pontuação: ${user.quantity.toLocaleString()}`} // Ajuste para a propriedade correta
                        primaryTypographyProps={{ fontWeight: 'bold', fontSize: '1rem' }}
                        secondaryTypographyProps={{ fontSize: '0.9rem', color: 'gray' }}
                      />
                    </ListItem>
                  ))}
                </List>
              )}

              {!isLoadingProcessing && (usersProcessRank === undefined || usersProcessRank.length === 0) && (
                <Typography variant="h6" color="textSecondary">
                  Nenhum dado disponível
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>
        <Card sx={{ width: '49%', maxWidth: '100%' }}>
          <CardContent>
            <Typography variant="h5" align="center" gutterBottom>
              Rank de Chaves Validadas Ultimos 7 Dias
            </Typography>

            <Box padding={2} display="flex" flexDirection="column" alignItems="center">
              {isLoadingProcessing && <CircularProgress />}

              {!isLoadingProcessing && usersProcessRank7Day !== undefined && usersProcessRank7Day.length > 0 && (
                <List sx={{ width: '100%' }}>
                  {usersProcessRank7Day.map((user, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        borderBottom: '1px solid #ddd',
                        '&:last-child': { borderBottom: 'none' },
                        padding: '10px 0',
                      }}
                    >
                      <ListItemText
                        primary={user.fullname} // Ajuste para a propriedade correta
                        secondary={`Pontuação: ${user.quantity.toLocaleString()}`} // Ajuste para a propriedade correta
                        primaryTypographyProps={{ fontWeight: 'bold', fontSize: '1rem' }}
                        secondaryTypographyProps={{ fontSize: '0.9rem', color: 'gray' }}
                      />
                    </ListItem>
                  ))}
                </List>
              )}

              {!isLoadingProcessing && (usersProcessRank7Day === undefined || usersProcessRank7Day.length === 0) && (
                <Typography variant="h6" color="textSecondary">
                  Nenhum dado disponível
                </Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </LayoutBaseDePagina>
  );
};
