import { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CircularProgress, TextField, Typography } from '@mui/material';
import * as yup from 'yup';

import { useAuthContext } from '../../contexts';
import { UserService } from '../../services/api/users/UserService';
import ReCAPTCHA from 'react-google-recaptcha';
import { Inicio } from '../inicio/Inicio';

const loginSchema = yup.object().shape({
  email: yup.string().email().required('O email é obrigatório.'),
  password: yup.string().required('A senha é obrigatória.').min(5, 'A senha deve ter pelo menos 5 caracteres.'),
});

const registerSchema = yup.object().shape({
  email: yup.string().email().required('O email é obrigatório.'),
  password: yup.string().required('A senha é obrigatória.').min(5, 'A senha deve ter pelo menos 5 caracteres.'),
  fullName: yup.string().required('O nome completo é obrigatório.'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password')], 'As senhas não coincidem.')
    .required('A confirmação de senha é obrigatória.'),
    indication: yup.string(), // Tornar a validação de indication opcional
});


interface ILoginProps {
  children: React.ReactNode;
}

export const Login: React.FC<ILoginProps> = ({ children }) => {
  const { isAuthenticated, login } = useAuthContext();
  const [isInicio, setInicio] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
 


  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [fullNameError, setFullNameError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [indication, setIndication] = useState('');






  const handleLogin = () => {
    if (!captchaValue) {
      alert('Por favor, complete o CAPTCHA.');
      return;
    }

    setIsLoading(true);
    loginSchema
      .validate({ email, password }, { abortEarly: false })
      .then((dadosValidados) => {
        login(dadosValidados.email, dadosValidados.password)
          .then((authResult) => {
            if (authResult instanceof Error) {
              console.log("ERRRO")
              setEmailError(authResult.message);
              setPasswordError(authResult.message);
            }
            setIsLoading(false)
          })
          .catch(() => setIsLoading(false));
      })
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);
        errors.inner.forEach((error) => {
          if (error.path === 'email') setEmailError(error.message);
          if (error.path === 'password') setPasswordError(error.message);
        });
      });
  };

  const handleRegister = () => {
    if (!captchaValue) {
      alert('Por favor, complete o CAPTCHA.');
      return;
    }

    setIsLoading(true);
    registerSchema
    .validate({ email, password, fullName, confirmPassword, indication }, { abortEarly: false })
    .then((dadosValidados) => {
      UserService.register(dadosValidados)
        .then((result) => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Cadastro realizado com sucesso! Agora você pode fazer login.');
            setIsRegistering(false);
            setEmail('');
            setPassword('');
            setFullName('');
            setConfirmPassword('');
            setIndication('');
          }
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    })
  
      .catch((errors: yup.ValidationError) => {
        setIsLoading(false);
        errors.inner.forEach((error) => {
          if (error.path === 'email') setEmailError(error.message);
          if (error.path === 'password') setPasswordError(error.message);
          if (error.path === 'fullName') setFullNameError(error.message);
          if (error.path === 'confirmPassword') setConfirmPasswordError(error.message);
        });
      });
  };


  const handleCaptchaChange = (value: string | null) => {
    setCaptchaValue(value);
    console.log(value);
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const isRegistering = searchParams.get("isRegistering");
    const codigoIndicacao = searchParams.get("codigoIndicacao");
    const isRegisteringBoolean = isRegistering === "true";
    const isInicio = searchParams.get("isInicio");
    const isInicioBoolean = isInicio ? isInicio === "true" : true;
  
    // Define os parâmetros no state 
    if (isRegistering) {
      setIsRegistering(isRegisteringBoolean);
    }
    if (codigoIndicacao) {
      setIndication(codigoIndicacao);
    }
    setInicio(isInicioBoolean); 
  }, []);

  if (isAuthenticated) return <>{children}</>;

  return (
    <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
      {isInicio && <Inicio setInicio={setInicio} />}
      {!isAuthenticated && !isInicio && (
        <Card>
          <CardContent>
            <Box display="flex" flexDirection="column" gap={2} width={300}>
              <Typography variant="h6" align="center">
                {isRegistering ? 'Registre-se' : 'Identifique-se'}
              </Typography>

              {isRegistering && (
                <TextField
                  fullWidth
                  label="Nome Completo"
                  value={fullName}
                  disabled={isLoading}
                  error={!!fullNameError}
                  helperText={fullNameError}
                  onKeyDown={() => setFullNameError('')}
                  onChange={(e) => setFullName(e.target.value)}
                />
              )}
              <TextField
                fullWidth
                type="email"
                label="Email"
                value={email}
                disabled={isLoading}
                error={!!emailError}
                helperText={emailError}
                onKeyDown={() => setEmailError('')}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                fullWidth
                label="Senha"
                type="password"
                value={password}
                disabled={isLoading}
                error={!!passwordError}
                helperText={passwordError}
                onKeyDown={() => setPasswordError('')}
                onChange={(e) => setPassword(e.target.value)}
              />

              <Typography
                sx={{
                  fontSize: '0.875rem',
                  color: '#1976d2',
                  cursor: 'pointer',
                  marginTop: '-10px',
                  '&:hover': {
                    textDecoration: 'underline', // Sublinha no hover
                  },
                }}
              >
                Esqueci minha senha
              </Typography>



              {isRegistering && (
                <TextField
                  fullWidth
                  label="Confirmar Senha"
                  type="password"
                  value={confirmPassword}
                  disabled={isLoading}
                  error={!!confirmPasswordError}
                  helperText={confirmPasswordError}
                  onKeyDown={() => setConfirmPasswordError('')}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />

              )}
              {isRegistering && (
                <TextField
                  fullWidth
                  label="Código De Indicação"
                  value={indication}
                  disabled={isLoading}
                  onChange={(e) => setIndication(e.target.value)}
                />

              )}

              {/* Google reCAPTCHA V2 */}
              <ReCAPTCHA sitekey="6LewUYcqAAAAAKD-udttzvPwU67570yIflDtrLPX" onChange={handleCaptchaChange} />
            </Box>
          </CardContent>
          <CardActions>
            <Box width="100%" display="flex" justifyContent="space-between" padding={2}>
              {isRegistering ? (
                <Button
                  variant="outlined"
                  disabled={isLoading}
                  onClick={() => setIsRegistering(false)}
                >
                  Cancelar
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  disabled={isLoading}
                  onClick={() => setIsRegistering(true)}
                >
                  Registrar-se
                </Button>
              )}
              <Button
                variant="contained"
                disabled={isLoading || !captchaValue}
                onClick={isRegistering ? handleRegister : handleLogin}
                endIcon={isLoading ? <CircularProgress variant="indeterminate" color="inherit" size={20} /> : undefined}
              >
                {isRegistering ? 'Registrar' : 'Entrar'}
              </Button>
              
            </Box>
          </CardActions>
        </Card>
      )}
    </Box>
  );
};
