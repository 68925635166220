import React, { useEffect, useState } from 'react';
import { Box, Button, List, ListItem, ListItemText, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Card, CardContent, CircularProgress } from '@mui/material';
import { IUserProcessingHistory, UserService } from '../../shared/services/api/users/UserService';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { FerramentasDaListagem } from '../../shared/components';
import { useAuthContext } from '../../shared/contexts';
import { Environment } from '../../shared/environment';
import { Line } from 'react-chartjs-2';

export interface IUIndication {
  id: string;
  idUID: string;
  fullName: string;
  totalProcess: string;
}

const ListaIndicados: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [linkGerado, setLinkGerado] = useState('');
  const [indicadoList, setIndicadosList] = useState<IUIndication[]>([]); // Inicializado corretamente como array
  const [isLoadingProcessing, setIsLoadingProcessing] = useState(true);
  const { isAuthenticated,  } = useAuthContext();
  const [uIID, setUIID] = useState<string>();
  
  // Função para gerar o link com um código de indicação
  const gerarLink = () => {
    
    const baseUrl = Environment.URL_BASE;
    const link = `${Environment.URL_BASE_FRONT}registrar?isRegistering=true&codigoIndicacao=${uIID}&isInicio=false`;
    setLinkGerado(link);
    setOpenModal(true); // Abre o modal
  };
  
  
  const [openModalHistorico, setOpenModalHistorico] = useState(false);
  const [isUserProcessingHistory, setUserProcessingHistory] = useState(false);
  const [lineValues, setLineValues] = useState<IUserProcessingHistory[]>();
  const [isLoadingProcessingHistory, setIsLoadingProcessingHistory] = useState(true);
  const currencyFormatter = (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  const handleHistoricoClick = (idUID: string) => {
    console.log(`Botão clicado para o item com ID: ${idUID}`);
    setUserProcessingHistory(true);
    UserService.getUserProcessingHistoryById(idUID)
      .then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {

          // Ordena a lista de acordo com a data
          const sortedData = result.sort((a, b) => {
            const dateA = new Date(a.updateDate);
            const dateB = new Date(b.updateDate);
            return dateA.getTime() - dateB.getTime(); // Ordena de forma crescente
          });

          setLineValues(sortedData); // Atualiza o estado com os dados ordenados
          setUserProcessingHistory(false);
          setIsLoadingProcessingHistory(false);
        }
      });
      setOpenModalHistorico(true);
  };

  const lineChartsParams = {
    labels: lineValues?.map(item => item.updateDate), // Mapeia as datas para 'Dia: X'
    datasets: [
      {
        data: lineValues?.map(item => item.totalQuantity), // Mapear os valores reais de totalQuantity
        label: 'Processamento (em energia)',
        borderColor: '#FF6384',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        fill: true,
      },
    ],
  };

  // Busca o processamento do usuário
  useEffect(() => {
    setIsLoadingProcessing(true); // Liga o loading
  
    UserService.getIndication()
      .then((result) => {
        setIsLoadingProcessing(false); // Desliga o loading
  
        if (result instanceof Error) {
          alert(result.message);
        } else {     
  
            setIndicadosList(result.indications); // Atualiza a lista de indicações
            setUIID(result.id); // Atualiza os IDs (se necessário, escolha como manipular múltiplos IDs)
          
        }
      });
  }, []);
  


  return (
    
    <LayoutBaseDePagina
            titulo={"Lista de Indicados"}
            barraDeFerramentas={<FerramentasDaListagem mostrarBotaoNovo={false} />}
        >
    <Box padding={2} component={Paper} elevation={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Typography variant="h4">Indicados</Typography>
        <Button variant="contained" color="primary" onClick={gerarLink}>
          Convidar
        </Button>
      </Box>

      <List sx={{ width: '100%' }}>
        {indicadoList.map((indicado: IUIndication) => (
          <ListItem key={indicado.id} divider>
            <ListItemText 
              primary={indicado.fullName}
              secondary={
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <span>Total processado: {indicado.totalProcess.toLocaleString()}</span>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    size="small" 
                    onClick={() => handleHistoricoClick(indicado.idUID)}
                  >
                    Ver Histórico
                  </Button>
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>

      {/* Modal para exibir o link gerado */}
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Link de Convite Gerado</DialogTitle>
        <DialogContent>
          <TextField
            label="Link"
            fullWidth
            value={linkGerado}
            InputProps={{
              readOnly: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="secondary">
            Fechar
          </Button>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(linkGerado);
              alert('Link copiado para a área de transferência!');
            }}
            color="primary"
          >
            Copiar Link
          </Button>
        </DialogActions>
      </Dialog>


      {/* Modal para exibir o historico de processamento */}
      <Dialog open={openModalHistorico} onClose={() => setOpenModalHistorico(false)}>
        <DialogTitle>Link de Convite Gerado</DialogTitle>
        <DialogContent>
          <Card sx={{ width: '100%', maxWidth: '100%' }}>
            <CardContent>
              <Typography variant="h5" align="center">
                Histórico de Processamento
              </Typography>

              <Box padding={2} display="flex" flexDirection="column" alignItems="center">
                {!isLoadingProcessingHistory && (
                  <>
                    {/* Novo gráfico de linha para Processamento */}
                    <Box marginTop={4} width="100%" display="flex" justifyContent="center">
                      <Line
                        data={lineChartsParams} // Adicionando o 'data' aqui
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              position: 'top',
                            },
                            tooltip: {
                              callbacks: {
                                label: function (context) {
                                  const dataset = context.dataset.data;
                                  const currentValue = dataset[context.dataIndex];

                                  // Verifique se currentValue é um número ou um objeto do tipo 'Point'
                                  const value = typeof currentValue === 'number'
                                    ? currentValue
                                    : (currentValue as { y: number }).y; // Forçar como 'Point' e acessar 'y'

                                  // Agora 'value' é garantidamente um número, então pode ser passado para currencyFormatter
                                  return value !== undefined ? currencyFormatter(value) : '';
                                },
                              },
                            },

                          },
                        }}
                        height={250}
                        width={400}
                      />
                    </Box>
                  </>
                )}
                {isLoadingProcessingHistory && (
                  <CircularProgress />
                )}
              </Box>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalHistorico(false)} color="secondary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </LayoutBaseDePagina>
  );
};

export default ListaIndicados;
