import { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';
import { IUserProcessing, IUserProcessingHistory, UserService } from '../../shared/services/api/users/UserService';
import { Cotacao } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';

// Registre os elementos necessários para o Pie Chart e o Line Chart
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);

export const Dashboard = () => {
  const [isLoadingProcessing, setIsLoadingProcessing] = useState(true);
  const [isLoadingProcessingHistory, setIsLoadingProcessingHistory] = useState(true);
  const [isUserProcessingHistory, setUserProcessingHistory] = useState(false);
  const [userProcessing, setUserProcessing] = useState<IUserProcessing | null>(null);
  const [onlineUsers, setOnlineUsers] = useState<number | null>(null);
  const [lineValues, setLineValues] = useState<IUserProcessingHistory[]>();
  const [energPrice, setEnergPrice] = useState<number | null>(null);
  const [bitcoinPrice, setBitcoinPrice] = useState<string>('');
  const [totalAproximado, setTotalAproximado] = useState<string | null>(null);
  const [dolarPrice, setDolarPrice] = useState<number | null>(null);

  // Formatação de valores para a moeda
  const currencyFormatter = (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });

  // Dados do gráfico de linha
  //const years = ['Dia: 1', 'Dia: 2', 'Dia: 3', 'Dia: 4', 'Dia: 5', 'Dia: 6', 'Dia: 7']; // Exemplo de datas
  const lineChartsParams = {
    labels: lineValues?.map(item => item.updateDate), // Mapeia as datas para 'Dia: X'
    datasets: [
      {
        data: lineValues?.map(item => item.totalQuantity), // Mapear os valores reais de totalQuantity
        label: 'Processamento (em energia)',
        borderColor: '#FF6384',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        fill: true,
      },
    ],
  };

  // Busca o vlor do bitcoin 
  useEffect(() => {
    const fetchBitcoinPrice = async () => {
      try {
        const response = await fetch('https://api.coindesk.com/v1/bpi/currentprice/USD.json');
        const data = await response.json();
        const bitcoinPrice = data.bpi.USD.rate_float;
        setBitcoinPrice(bitcoinPrice);
        if (userProcessing) {
          const energiaProduzida = Number(userProcessing.total) / 34560000; // Divisão do processamento total
          const valorEnergia = (3.5 / energiaProduzida) * bitcoinPrice; // Cálculo do valor da energia
          setEnergPrice(valorEnergia); // Atualiza o estado com o valor calculado

          const totalCalculado = Number(
            (Number(valorEnergia.toFixed(2)) * parseFloat(userProcessing.energy)).toFixed(2)
          );
          const totalFormatado = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalCalculado);

          setTotalAproximado(totalFormatado); // Atualiza o estado com o valor calculado e formatado
        }
      } catch (error) {
        console.error('Erro ao buscar o preço do Bitcoin:', error);
      }
    };

    fetchBitcoinPrice();
    const interval = setInterval(fetchBitcoinPrice, 60000); // Atualiza a cada 60 segundos

    return () => clearInterval(interval);
  }, [userProcessing]);

  // useEffect para buscar o valor do Dólar
  // useEffect para buscar o valor do Dólar
  useEffect(() => {
    const fetchDolarPrice = async (): Promise<void> => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD'); // API de exemplo
        const data: { rates: { [key: string]: number } } = await response.json();
        const dolarPrice = data.rates.BRL; // Valor do dólar em reais
        setDolarPrice(dolarPrice); // Atualiza o estado com o valor do dólar
      } catch (error) {
        console.error('Erro ao buscar o preço do Dólar:', error);
      }
    };

    fetchDolarPrice();
    const interval = setInterval(fetchDolarPrice, 60000); // Atualiza a cada 60 segundos

    return () => clearInterval(interval);
  }, []);

  // Busca o processamento do usuário
  useEffect(() => {
    setIsLoadingProcessing(true);
    UserService.getUserProcessing()
      .then((result) => {
        setIsLoadingProcessing(false);
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setUserProcessing(result);
        }
      });
  }, []);

  // Busca os mineradores online
  useEffect(() => {
    UserService.getOnlineUsers()
      .then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setOnlineUsers(result);
        }
      });
  }, []);



  useEffect(() => {
    setIsLoadingProcessingHistory(true);
    if (!isUserProcessingHistory) {
      setUserProcessingHistory(true);
      UserService.getUserProcessingHistory()
        .then((result) => {
          if (result instanceof Error) {
            alert(result.message);
          } else {

            // Ordena a lista de acordo com a data
            const sortedData = result.sort((a, b) => {
              const dateA = new Date(a.updateDate);
              const dateB = new Date(b.updateDate);
              return dateA.getTime() - dateB.getTime(); // Ordena de forma crescente
            });

            setLineValues(sortedData); // Atualiza o estado com os dados ordenados
            setUserProcessingHistory(false);
            setIsLoadingProcessingHistory(false);
          }
        });
    } else {
      console.log("CHAMADA DESNECESSARIA")
    }
  }, []);

  return (
    <LayoutBaseDePagina
      titulo="Dashboard"
      barraDeFerramentas={<Cotacao mostrarBitcoin={true} valorBitcoin={bitcoinPrice} mostrarEnergia valorEnergia={energPrice?.toString()} mostrarDolar valorDolar={dolarPrice?.toString()} />}
    >
      <Box display="flex" flexWrap="wrap" justifyContent="center" gap={2} margin={2}>
        {/* Card de Processamento do Usuário */}
        <Card sx={{ width: '49%', maxWidth: '100%' }}>
          <CardContent>
            <Typography variant="h5" align="center">
              Chaves Validadas pelo  Usuário
            </Typography>

            <Box padding={2} display="flex" flexDirection="column" alignItems="center">
              {!isLoadingProcessing && userProcessing && (
                <>
                  <Typography variant="body1">
                    <strong>Chaves Validadas:</strong> {userProcessing.quantity.toLocaleString()}{/* aki */}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Total de Chaves Processadas:</strong> {userProcessing.total.toLocaleString()}
                  </Typography>
                  <Typography variant="body1" style={{ fontSize: '16px', fontWeight: 500 }}>
                    <strong>Tokens de Energia Gerados:</strong> {userProcessing.energy.toLocaleString()} =
                    <span
                      style={{

                        fontSize: '18px',
                        fontWeight: 'bold',
                        marginLeft: '8px', // Espaço entre o valor e o símbolo
                        background: 'linear-gradient(45deg, #66bb6a, #388e3c)', // Gradiente de verde
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                      }}
                    >
                      {totalAproximado ? totalAproximado : 'Carregando...'}
                    </span>
                  </Typography>


                  <Typography variant="body1">
                    <strong>Mineradores Conectados:</strong> {onlineUsers}
                  </Typography>
                  {/* Botão Condicional */}
                  <Box marginTop={2}>

                    <Button
                      variant="contained"
                      disabled
                      style={{
                        backgroundColor: userProcessing.winKey ? "green" : "red",
                        color: "white",
                        cursor: "not-allowed",
                      }}
                    >
                      {userProcessing.winKey ? "Chave Encontrada" : "Chave Não Encontrada..."}
                    </Button>
                  </Box>

                  <Box marginTop={2} width="100%" display="flex" justifyContent="center">
                    <Pie
                      data={{
                        labels: ['Meu Processamento', 'Restante'],
                        datasets: [
                          {
                            data: userProcessing
                              ? [
                                Number(userProcessing.quantity), // Meu processamento
                                Number(userProcessing.total) - Number(userProcessing.quantity), // Restante
                              ]
                              : [0, 0],
                            backgroundColor: ['#FF6384', '#36A2EB'],
                          },
                        ],
                      }}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            position: 'top',
                          },
                          tooltip: {
                            callbacks: {
                              label: function (context) {
                                const dataset = context.dataset.data;
                                const currentValue = Number(dataset[context.dataIndex]);
                                const totalValue = dataset.reduce((sum, value) => sum + Number(value), 0);
                                const percentage = ((currentValue / totalValue) * 100).toFixed(2);
                                return `${currentValue.toLocaleString()} (${percentage}%)`;
                              },
                            },
                          },
                        },
                      }}
                      height={200}
                      width={200}
                    />
                  </Box>
                </>
              )}
              {isLoadingProcessing && (
                <CircularProgress />
              )}
            </Box>
          </CardContent>
        </Card>
        {/* Card de Processamento do Usuário */}
        <Card sx={{ width: '49%', maxWidth: '100%' }}>
          <CardContent>
            <Typography variant="h5" align="center">
              Histórico de Processamento
            </Typography>

            <Box padding={2} display="flex" flexDirection="column" alignItems="center">
              {!isLoadingProcessingHistory && (
                <>
                  {/* Novo gráfico de linha para Processamento */}
                  <Box marginTop={4} width="100%" display="flex" justifyContent="center">
                    <Line
                      data={lineChartsParams} // Adicionando o 'data' aqui
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            position: 'top',
                          },
                          tooltip: {
                            callbacks: {
                              label: function (context) {
                                const dataset = context.dataset.data;
                                const currentValue = dataset[context.dataIndex];

                                // Verifique se currentValue é um número ou um objeto do tipo 'Point'
                                const value = typeof currentValue === 'number'
                                  ? currentValue
                                  : (currentValue as { y: number }).y; // Forçar como 'Point' e acessar 'y'

                                // Agora 'value' é garantidamente um número, então pode ser passado para currencyFormatter
                                return value !== undefined ? currencyFormatter(value) : '';
                              },
                            },
                          },

                        },
                      }}
                      height={250}
                      width={400}
                    />
                  </Box>
                </>
              )}
              {isLoadingProcessingHistory && (
                <CircularProgress />
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </LayoutBaseDePagina>
  );
};
