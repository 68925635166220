import { Box, Card, CardContent, Typography } from '@mui/material';
import { LayoutBaseDePagina } from '../../shared/layouts';

export const SobreProjeto = () => {
    return (
        <LayoutBaseDePagina
            titulo="Projeto Minerador Distribuído"
            barraDeFerramentas={null}
        >
            <Box width="100%" display="flex" flexDirection="column" alignItems="center" padding={0}>
                <Card style={{ margin: "10px" }}>
                    <CardContent>
                        {/* Introdução */}
                        <Box width="100%" maxWidth="800px" marginBottom={4}>
                            <Typography variant="h4" align="center" gutterBottom color='secondary'>
                                Sobre o Projeto
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Este projeto tem como objetivo criar um sistema distribuído capaz de resolver um quebra-cabeça envolvendo chaves privadas de Bitcoin. O desafio foi lançado em 2015 e oferece prêmios crescentes que totalizam <strong>1000 BTC</strong>.
                            </Typography>
                            <Typography variant="body1" paragraph>
                                O sistema utilizará técnicas de computação distribuída para explorar o espaço de chaves privadas e resolver os desafios restantes, contribuindo também para o estudo dos limites do poder computacional.
                            </Typography>
                        </Box>

                        {/* Card de Destaque */}
                        <Box width="100%" maxWidth="800px" marginBottom={4} color='secondary'>
                            <Typography variant="h5" align="center" gutterBottom color='secondary'>
                                Destaque
                            </Typography>
                            <Typography variant="body1" align="center" paragraph>
                                As chaves restantes oferecem prêmios de até <strong>16 BTC</strong> cada. O desafio testa os limites da computação e pode trazer grandes recompensas!
                            </Typography>
                        </Box>

                        {/* História */}
                        <Box width="100%" maxWidth="800px" marginBottom={4}>
                            <Typography variant="h4" align="center" gutterBottom color='secondary'>
                                História do Quebra-Cabeça
                            </Typography>
                            <Typography variant="body1" paragraph>
                                Em <strong>15/01/2015</strong>, foi criada uma transação com 256 endereços Bitcoin, cada um contendo uma chave privada protegida. Desde então, algumas chaves foram resolvidas, mas muitas ainda permanecem intocadas.
                            </Typography>
                            <Typography variant="body1">
                                <ul>
                                    <li><strong>2015:</strong> Quebra-cabeça criado com prêmios distribuídos em 256 endereços.</li>
                                    <li><strong>2019:</strong> O criador aumentou os prêmios dos desafios restantes.</li>
                                    <li><strong>2024:</strong> Os prêmios não resolvidos foram reajustados, chegando a até <strong>16 BTC</strong> por chave.</li>
                                </ul>
                            </Typography>
                        </Box>

                        {/* Fonte */}
                        <Box width="100%" maxWidth="800px" textAlign="center">
                            <Typography variant="body2">
                                Fonte: <a href="https://privatekeys.pw/puzzles/bitcoin-puzzle-tx#p1" target="_blank" rel="noopener noreferrer">privatekeys.pw</a>
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </LayoutBaseDePagina>
    );
};
