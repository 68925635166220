import React from "react";
import { LayoutBaseDePagina } from "../../shared/layouts";
import { FerramentasDaListagem } from "../../shared/components";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography } from "@mui/material";
import { format } from 'date-fns'; // Para formatação da data

// Dados de exemplo com Private Key Range, pessoaQueAchou e dataDaSolucao
const dadosTabela = [
  {
    privateKeyRange: "fff", // Private Key Range
    key: "PrivateKey1 / PublicKey1",
    address: "1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa",
    status: "Active",
    rangePercentage: 25,
    solved: "Yes",
    pessoaQueAchou: "John Doe", // Nome da pessoa que achou
    dataDaSolucao: new Date('2024-11-10'), // Data de solução
  },
  {
    privateKeyRange: "f", // Private Key Range
    key: "PrivateKey2 / PublicKey2",
    address: "3J98t1WpEZ73CNmQViecrhM42zG41v84X2",
    status: "Inactive",
    rangePercentage: 50,
    solved: "No",
    pessoaQueAchou: "", // Nenhuma pessoa associada
    dataDaSolucao: null, // Não há data se não foi solucionado
  },
  {
    privateKeyRange: "fffff", // Private Key Range
    key: "PrivateKey3 / PublicKey3",
    address: "1PSSGeFjphRRe9EJrZ3bUG7yUqARy2Lwcf",
    status: "Active",
    rangePercentage: 80,
    solved: "Yes",
    pessoaQueAchou: "Alice Smith", // Nome da pessoa que achou
    dataDaSolucao: new Date('2024-11-15'), // Data de solução
  },
];

export const TabelaDePremios: React.FC = () => {
  return (
    <LayoutBaseDePagina
      titulo='Listagem dos Prêmios'
      barraDeFerramentas={<FerramentasDaListagem mostrarBotaoNovo={false} />}
    >
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {/* Coluna Private Key Range */}
              <TableCell sx={{ fontWeight: 'bold', color: 'black' }}>Private Key Range</TableCell>

              {/* Coluna Private Key / Public Key */}
              <TableCell sx={{ fontWeight: 'bold', color: 'black' }}>Private Key / Public Key</TableCell>

              {/* Coluna Bitcoin Address */}
              <TableCell sx={{ fontWeight: 'bold', color: 'black' }}>Bitcoin Address</TableCell>

              {/* Coluna Status */}
              <TableCell sx={{ fontWeight: 'bold', color: 'black' }}>Status</TableCell>

              {/* Coluna % of the Range */}
              <TableCell sx={{ fontWeight: 'bold', color: 'black' }}>% of the Range</TableCell>

              {/* Coluna Solved */}
              <TableCell sx={{ fontWeight: 'bold', color: 'black' }}>Solved</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dadosTabela.map((row, index) => (
              <TableRow key={index}>
                {/* Private Key Range */}
                <TableCell sx={{ color: 'blue' }}>{row.privateKeyRange}</TableCell>

                {/* Private Key / Public Key */}
                <TableCell sx={{ color: 'blue' }}>{row.key}</TableCell>

                {/* Bitcoin Address */}
                <TableCell sx={{ color: 'black' }}>{row.address}</TableCell>

                {/* Status: Solved ou Unsolved */}
                <TableCell sx={{ color: row.solved === "Yes" ? 'green' : 'red' }}>
                  {row.solved === "Yes" ? "Solved" : "Unsolved"}
                </TableCell>

                {/* % of the Range */}
                <TableCell>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Box
                      sx={{
                        height: 10,
                        width: `${row.rangePercentage}%`,
                        backgroundColor: 'blue',
                        borderRadius: '5px',
                      }}
                    />
                    <Typography variant="body2" sx={{ color: 'blue' }}>
                      {row.rangePercentage}%
                    </Typography>
                  </Box>
                </TableCell>

                {/* Solved: Data de solução e nome da pessoa que achou */}
                <TableCell sx={{ color: 'black' }}>
                  {row.solved === "Yes" ? (
                    <>
                      <Typography variant="body2">
                        {row.dataDaSolucao ? format(row.dataDaSolucao, 'yyyy/MM/dd') : "-"} - {row.pessoaQueAchou}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant="body2">-</Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};
