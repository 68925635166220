import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { AuthService } from '../services/api/auth/AuthService';

interface IAuthContextData {
  logout: () => Promise<void>;
  isAuthenticated: boolean;
  login: (email: string, password: string) => Promise<void | Error>;
}

const AuthContext = createContext({} as IAuthContextData);

interface IAuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Verifica com o servidor se o usuário está autenticado
  const checkAuthentication = useCallback(async () => {
    try {
     /* UserService.getMe()
      .then((result) => {


        if (result instanceof Error) {
          alert(result.message);
        } else {
          alert(result);
        }
      }); */
      const isAuth = await AuthService.checkAuth(); // Endpoint que retorna status de autenticação
      setIsAuthenticated(isAuth);
    } catch {
      setIsAuthenticated(false);
    }
  }, []);

  // Login salva o JWT como cookie HTTP-only no servidor
  const handleLogin = useCallback(async (email: string, password: string) => {
    try {
      console.log("LOGANDOOOO");
      const authResult = await AuthService.auth(email, password); // Faz o login no servidor
      if (authResult instanceof Error) {
        return authResult;
      }
      console.log("CHECANDO");
      await checkAuthentication(); // Atualiza estado baseado no servidor
    } catch (error) {
      console.error('Erro ao fazer login:', error);
    }
  }, [checkAuthentication]);

  // Logout remove o cookie no servidor
  const handleLogout = useCallback(async () => {
    try {
      await AuthService.logout(); // Endpoint para apagar o cookie
      setIsAuthenticated(false);
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  }, []);

  // Verifica autenticação ao montar o componente
  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login: handleLogin, logout: handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
